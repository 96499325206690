import { MODAL } from '../../constants/ActionTypes'

const initialState = {
  modalContentProps: {},
  modalProps: {},
  modalType: null,
}

export default function modal(state = initialState, action = null) {
  switch (action.type) {
  case MODAL.OPEN_MODAL:
    return {
      modalContentProps: action.payload.modalContentProps,
      modalProps: action.payload.modalProps,
      modalType: action.payload.modalType,
    }

  case MODAL.CLOSE_MODAL:
    return initialState

  default:
    return state
  }
}
