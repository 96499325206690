/* eslint-disable import/max-dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { getUsername } from '@expediapartnersolutions/app-hub-utils/lib'
import { AppHubContent, AppHubFooter, AppHubMain, Navigation, Routes } from '@expediapartnersolutions/app-hub-components'
import { Button, LandingPage, Loading } from '@expediapartnersolutions/ocean'

import { logout } from '../../actions'
import Analytics from '../../utils/Analytics'
import AppsManager from '../AppsManager'
import Launchpad from '../Launchpad'
import ModalRoot from '../ModalRoot'

const deprecatingPortalAccounts = app => app.name !== 'dobby'

export class App extends React.Component {

  static propTypes = {
    appConfig: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    logoutFn: PropTypes.func.isRequired,
    user: PropTypes.object,
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoggedIn && !prevProps.isLoggedIn) {
      const hashedUsername = getUsername({ hashed: true })
      Analytics.login(hashedUsername)
    }
  }

  logout = () => {
    this.props.logoutFn()
  }

  render() {
    const { appConfig, isLoading, isLoggedIn, user } = this.props

    if (!isLoggedIn || isLoading) {
      return (
        <LandingPage title='Console is loading.' background='https://cdn.expediapartnersolutions.com/eps-com/eps-console-cover.jpg'>
          <Loading/>
        </LandingPage>
      )
    }

    if (!user || !appConfig) {
      return null
    }

    const userAccessRoute = <Route path='/apps-manager'><AppsManager/></Route>

    return (
      <AppHubMain theme='eps-internal'>
        <Navigation
          appConfig={appConfig}
          hasNavigationLinks={false}
          username={`${user.username}`.trim()}
        >
          <Button id='logout-button' onClick={this.logout}>Sign out</Button>
        </Navigation>
        <AppHubContent>
          <Routes appConfig={appConfig.filter(deprecatingPortalAccounts)} documentTitle='EPS Console' localRoutes={userAccessRoute}>
            <Launchpad appConfig={appConfig}/>
          </Routes>
          <AppHubFooter>
            {__VERSION__ && __COMMIT_HASH__ ? <small style={{ display: __DEV__ ? 'block' : 'none' }}>v{__VERSION__} ({__COMMIT_HASH__.substring(0, 7)})</small> : null}
          </AppHubFooter>
        </AppHubContent>
        <ModalRoot/>
      </AppHubMain>
    )
  }

}

export default connect(
  state => ({
    appConfig: state.auth.appConfig,
    isLoading: state.auth.isLoading,
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
  }), {
    logoutFn: logout,
  }
)(App)
