import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal } from '@expediapartnersolutions/ocean'

import { closeModal } from '../../actions'
import { MODAL_COMPONENTS } from '../../constants/ModalConfig'

export class ModalRoot extends React.PureComponent {

  static propTypes = {
    closeModalFn: PropTypes.func.isRequired,
    modalContentProps: PropTypes.object,
    modalProps: PropTypes.object,
    modalType: PropTypes.string,
  }

  render() {
    const { closeModalFn, modalContentProps, modalProps, modalType } = this.props

    if (!modalType) {
      return null
    }

    const ModalContent = MODAL_COMPONENTS[modalType]
    return (
      <Modal onCloseModal={closeModalFn} show {...modalProps}>
        <ModalContent {...modalContentProps}/>
      </Modal>
    )
  }

}

export default connect(
  state => ({
    modalContentProps: state.modal.modalContentProps,
    modalProps: state.modal.modalProps,
    modalType: state.modal.modalType,
  }), {
    closeModalFn: closeModal,
  }
)(ModalRoot)
