const EVENT_ACTION = {
  EDIT_APP_PERMISSIONS: 'EditAppPermission',
  MANAGE_APP_PERMISSION_DELETED: 'ManageAppPermissionDeleted',
  MANAGE_APP_PERMISSION_PROVIDED: 'ManageAppPermissionProvided',
  MANAGE_USERS_PERMISSION_DELETED: 'ManageUsersPermissionDeleted',
  MANAGE_USERS_PERMISSION_PROVIDED: 'ManageUsersPermissionProvided',
  NEW_APP_PERMISSIONS: 'NewAppPermissions',
  PERMISSION_ROLE_DELETED_CANCELLATION: 'PermissionRoleDeleteCancellation',
  PERMISSION_ROLE_DELETED_CONFIRMED: 'PermissionRoleDeleteConfirmed',
  PERMISSION_ROLE_DELETED_POP_UP: 'PermissionRoleDeletedPopUp',
  PERMISSION_ROLE_PROVIDED: 'PermissionRoleProvided',
  SAVE_YOUR_NEW_PASSWORD: 'SaveYourNewApp',
  SET_USERS_AND_PERMISSIONS: 'SetUsersAndPermissions',
  USER_SEARCHED: 'UserSearched',
}

const trackEvent = (eventAction, eventLabel, eventValue) => window.AppHubGlobal.analytics.trackEvent('User Access', eventAction, eventLabel, eventValue)

const editAppPermission = appId => trackEvent(EVENT_ACTION.EDIT_APP_PERMISSIONS, `EditAppPermission => appId:${appId}`)

const manageAppPermissionDeleted = () => trackEvent(EVENT_ACTION.MANAGE_APP_PERMISSION_DELETED, 'ManageAppPermissionDeleted')

const manageAppPermissionProvided = () => trackEvent(EVENT_ACTION.MANAGE_APP_PERMISSION_PROVIDED, 'ManageAppPermissionProvided')

const manageUsersPermissionDeleted = () => trackEvent(EVENT_ACTION.MANAGE_USERS_PERMISSION_DELETED, 'ManageUsersPermissionDeleted')

const manageUsersPermissionProvided = () => trackEvent(EVENT_ACTION.MANAGE_USERS_PERMISSION_PROVIDED, 'ManageUsersPermissionProvided')

const newAppPermissions = () => trackEvent(EVENT_ACTION.NEW_APP_PERMISSIONS, 'NewAppPermissions')

const permissionRoleDeleteCancellation = () => trackEvent(EVENT_ACTION.PERMISSION_ROLE_DELETED_CANCELLATION, 'PermissionRoleDeleteCancellation')

const permissionRoleDeleteConfirmed = () => trackEvent(EVENT_ACTION.PERMISSION_ROLE_DELETED_CONFIRMED, 'PermissionRoleDeleteConfirmed')

const permissionRoleDeletedPopUp = () => trackEvent(EVENT_ACTION.PERMISSION_ROLE_DELETED_POP_UP, 'PermissionRoleDeletedPopUp')

const permissionRoleProvided = () => trackEvent(EVENT_ACTION.PERMISSION_ROLE_PROVIDED, 'PermissionRoleProvided')

const saveYourNewApp = () => trackEvent(EVENT_ACTION.SAVE_YOUR_NEW_PASSWORD, 'SaveYourNewApp')

const setUsersAndPermissions = appId => trackEvent(EVENT_ACTION.SET_USERS_AND_PERMISSIONS, `SetUsersAndPermissions => appId:${appId}`)

const userSearched = () => trackEvent(EVENT_ACTION.USER_SEARCHED, 'UserSearched')

const AnalyticsUserAccess = {
  editAppPermission,
  manageAppPermissionDeleted,
  manageAppPermissionProvided,
  manageUsersPermissionDeleted,
  manageUsersPermissionProvided,
  newAppPermissions,
  permissionRoleDeleteCancellation,
  permissionRoleDeleteConfirmed,
  permissionRoleDeletedPopUp,
  permissionRoleProvided,
  saveYourNewApp,
  setUsersAndPermissions,
  userSearched,
}

export default AnalyticsUserAccess
