import { combineReducers } from 'redux'

import appsManager from './apps/AppsManager/reducer'
import auth from './apps/CoreApp/reducer'
import modal from './apps/ModalRoot/reducer'

const reducers = combineReducers({
  appsManager,
  auth,
  modal,
})

export default reducers
