export const hashDecoder = hash => {
  const dataString = hash.substr(1)

  return dataString.split('&').reduce((acc, item) => {
    const [key, value] = item.split('=')
    acc[key] = value

    return acc
  }, {})
}

export default class URLTools {

  constructor(queryParams) {
    this.queryParams = queryParams.startsWith('?') ? queryParams.substring(1) : queryParams
    this.objectParams = this.queryParamsToObject()
  }

  /**
   * This method will take the query params from a string
   * and returns key value pairs
   * @returns Object
   */
  queryParamsToObject() {
    if (!this.queryParams) {
      return {}
    }
    return this.queryParams.split('&').reduce((accumulator, currentValue) => {
      const [key, value] = currentValue.split('=')
      return {
        ...accumulator,
        [decodeURIComponent(key)]: decodeURIComponent(value),
      }
    }, {})
  }

  /**
   * This method will transform the existing
   * query params as object to string
   * @returns String
   */
  queryParamsToString() {
    if (Object.keys(this.objectParams).length === 0) {
      return ''
    }
    return `?${Object.keys(this.objectParams).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.objectParams[key])}`).join('&')}`
  }

  /**
   * This method will find the query param and returns it's value
   * @returns String
   */
  get(param) {
    return this.objectParams[param]
  }

  /**
   * This method will set the new query param to the existing list
   * and returns the concatenated list of query params
   * @returns String
   */
  set(param, value) {
    this.objectParams[param] = value
    return this.queryParamsToString()
  }

}
