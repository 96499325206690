import CookieUtils from './CookieUtils'

export const SESSION = {
  identityId: '_ia_id',
  token: '_ia_token',
  user: 'user',
}

export default class SessionUtils {

  static getUser() {
    const user = window.localStorage.getItem(SESSION.user)

    if (!!CookieUtils.get(SESSION.token) && user !== null) {
      return JSON.parse(user)
    }
    return null
  }

  static clear() {
    CookieUtils.expire(SESSION.token)
    window.localStorage.removeItem(SESSION.user)
  }

}
