/* eslint-disable complexity */
import { APPS_MANAGER } from '../../constants/ActionTypes'
import ComparisonUtils from '../../utils/ComparisonUtils'

const initialState = {
  apps: [],
  deletedUserId: null,
  error: null,
  hasAppBeenCreated: false,
  hasAppBeenUpdated: false,
  isLoading: false,
  permissionsForSelectedUser: null,
}

export default function appsManager(state = initialState, action = null) {
  switch (action.type) {
  case APPS_MANAGER.ALL_APPS_REQUEST:
    return {
      ...state,
      addUsers: false,
      appUsers: [],
      error: null,
      isLoading: true,
      permissionsForSelectedUser: null,
    }

  case APPS_MANAGER.ALL_APPS_SUCCESS:
    return {
      ...state,
      apps: action.payload,
      error: null,
      isLoading: false,
    }

  case APPS_MANAGER.ALL_APPS_FAILURE:
    return {
      ...state,
      error: (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) || 'An error occurred loading Apps list.',
      isLoading: false,
    }

  case APPS_MANAGER.CREATE_APP_REQUEST:
    return {
      ...state,
      addUsers: false,
      error: null,
      hasAppBeenCreated: false,
      isLoading: true,
    }

  case APPS_MANAGER.CREATE_APP_SUCCESS:
    return {
      ...state,
      addUsers: false,
      error: null,
      hasAppBeenCreated: true,
      isLoading: false,
    }

  case APPS_MANAGER.CREATE_APP_FAILURE:
    return {
      ...state,
      addUsers: false,
      error: (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) || 'An error occurred when creating App.',
      hasAppBeenCreated: false,
      isLoading: false,
    }

  case APPS_MANAGER.CREATE_UPDATE_APP_RESET:
    return {
      ...state,
      hasAppBeenCreated: false,
      hasAppBeenUpdated: false,
      updatingApp: null,
    }

  case APPS_MANAGER.CLEAR_UPDATING_APP:
    return {
      ...state,
      updatingApp: null,
    }

  case APPS_MANAGER.GET_USERS_AND_SCHEMA_FOR_APP:
    return {
      ...state,
      addUsers: true,
      error: null,
      isLoading: true,
      message: null,
    }

  case APPS_MANAGER.GET_USERS_AND_SCHEMA_FOR_APP_SUCCESS:
    return {
      ...state,
      addUsers: true,
      appUsers: action.payload.users.sort(ComparisonUtils.compareUsersByName),
      currentAppPermissionsSchema: action.payload.schema,
      error: null,
      isLoading: false,
      ldapUsers: [],
      message: null,
    }

  case APPS_MANAGER.GET_USERS_AND_SCHEMA_FOR_APP_FAILURE:
    return {
      ...state,
      addUsers: true,
      appUsers: [],
      currentAppPermissionsSchema: null,
      error: (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) || 'An error occurred while searching users.',
      isLoading: false,
      ldapUsers: [],
    }

  case APPS_MANAGER.GET_USER_PERMISSIONS:
    return {
      ...state,
      addUsers: true,
      deletedUserId: null,
      error: null,
      isLoading: true,
      message: null,
      permissionsForSelectedUser: null,
    }

  case APPS_MANAGER.GET_USER_PERMISSIONS_SUCCESS: {
    return {
      ...state,
      addUsers: true,
      error: null,
      isLoading: false,
      isLoadingModal: false,
      message: null,
      permissionsForSelectedUser: action.payload,
    }
  }

  case APPS_MANAGER.GET_USER_PERMISSIONS_FAILURE:
    return {
      ...state,
      addUsers: true,
      error: (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) || 'An error occurred while getting user\'s permissions.',
      isLoading: false,
      message: null,
      permissionsForSelectedUser: null,
    }

  case APPS_MANAGER.SAVE_USER_PERMISSIONS:
    return {
      ...state,
      addUsers: true,
      error: null,
      isLoading: true,
      message: null,
      permissionsForSelectedUser: null,
    }

  case APPS_MANAGER.SAVE_USER_PERMISSIONS_SUCCESS: {
    const { payload } = action
    const existingAppUser = state.appUsers.find(user => payload.userId === user.userId)
    if (existingAppUser) {
      existingAppUser.permissions = payload.permissions
    }
    return {
      ...state,
      addUsers: true,
      appUsers: state.appUsers.some(user => payload.userId === user.userId) ? state.appUsers : [payload, ...state.appUsers].sort(ComparisonUtils.compareUsersByName),
      error: null,
      isLoading: false,
      message: 'User permissions saved successfully!',
      permissionsForSelectedUser: payload.permissions,
    }
  }

  case APPS_MANAGER.SAVE_USER_PERMISSIONS_FAILURE:
    return {
      ...state,
      addUsers: true,
      error: (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) || 'An error occurred while saving user\'s permissions.',
      isLoading: false,
      message: null,
    }

  case APPS_MANAGER.UPDATE_APP_REQUEST:
    return {
      ...state,
      error: null,
      hasAppBeenUpdated: false,
      isLoading: true,
    }

  case APPS_MANAGER.UPDATE_APP_SUCCESS:
    return {
      ...state,
      error: null,
      hasAppBeenUpdated: true,
      isLoading: false,
      updatingApp: null,
    }

  case APPS_MANAGER.UPDATE_APP_FAILURE:
    return {
      ...state,
      error: (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) || 'Unknown error, could not update app.',
      hasAppBeenUpdated: false,
      isLoading: false,
    }

  case APPS_MANAGER.DELETE_USER_PERMISSIONS:
    return {
      ...state,
      error: null,
      isLoadingModal: true,
    }

  case APPS_MANAGER.DELETE_USER_PERMISSIONS_SUCCESS:
    return {
      ...state,
      appUsers: state.appUsers.filter(user => action.payload.userId !== user.userId),
      deletedUserId: action.payload.userId,
      error: null,
      isLoadingModal: false,
      permissionsForSelectedUser: null,
    }

  case APPS_MANAGER.DELETE_USER_PERMISSIONS_FAILURE:
    return {
      ...state,
      error: (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) || 'Unknown error, could not delete user permissions.',
      isLoadingModal: false,
    }

  case APPS_MANAGER.GET_APP_SCHEMA:
    return {
      ...state,
      error: null,
      isDeprecatedAppSchema: false,
      isLoading: true,
      updatingApp: null,
    }

  case APPS_MANAGER.GET_APP_SCHEMA_SUCCESS:
    return {
      ...state,
      error: null,
      isDeprecatedAppSchema: action.payload.isDeprecatedSchema,
      isLoading: false,
      updatingApp: action.payload,
    }

  case APPS_MANAGER.GET_APP_SCHEMA_FAILURE:
    return {
      ...state,
      error: (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) || 'An error occurred when loading the app schema.',
      isDeprecatedAppSchema: false,
      isLoading: false,
      updatingApp: null,
    }

  default:
    return state
  }
}
