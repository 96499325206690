const prefixRegexExp = new RegExp('^[a|s|t|v][-]')
const isUserWithPrefix = userId => prefixRegexExp.test(userId)

const getUserWithoutPrefix = userId => userId.replace(prefixRegexExp, '')

export {
  isUserWithPrefix,
  getUserWithoutPrefix,
}

