import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Checkbox } from '@expediapartnersolutions/ocean'

import JSONSchema from '../../../components/JSONSchema'
import { MODAL_KEYS } from '../../../constants/ModalConfig'
import { USER_ACCESS_PERMISSIONS } from '../../../constants/Permissions'
import AnalyticsUserAccess from '../../../utils/analyticsUserAccess'
import { isUserWithPrefix, getUserWithoutPrefix } from '../../../utils/UserPrefix'

const APP_SCHEMA_MARGIN = 20

export class AppSchema extends React.Component {

  static propTypes = {
    appId: PropTypes.string.isRequired,
    appName: PropTypes.string.isRequired,
    appSchema: PropTypes.object.isRequired,
    currentUserId: PropTypes.string.isRequired,
    currentUserPermissions: PropTypes.object.isRequired,
    error: PropTypes.string,
    hasUserGotPermissions: PropTypes.bool,
    message: PropTypes.string,
    openModalFn: PropTypes.func.isRequired,
    permissionsForSelectedUser: PropTypes.object,
    saveUserPermissionsForAppFn: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  }

  static defaultProps = {
    permissionsForSelectedUser: {},
  }

  constructor(props) {
    super(props)
    const { defaultPermissionsForSelectedUser, userPermissionsWithoutDefaults } = this.extractUsersPermissions(props.appSchema, props.permissionsForSelectedUser)
    this.state = {
      appId: props.appId,
      appName: props.appName,
      currentUserId: props.currentUserId,
      defaultPermissionsForSelectedUser: { ...defaultPermissionsForSelectedUser },
      permissionsForSelectedUser: userPermissionsWithoutDefaults,
      user: props.user,
    }
  }

  extractUsersPermissions(appSchema, permissionsForSelectedUser) {
    const combinedUserPermissionsAndAppSchema = { ...appSchema, ...permissionsForSelectedUser }

    const userPermissionsWithoutDefaults = Object.entries(combinedUserPermissionsAndAppSchema)
      .filter(([permissionName]) => ![USER_ACCESS_PERMISSIONS.manageApp, USER_ACCESS_PERMISSIONS.manageUsers].includes(permissionName))
      .reduce((acc, [permissionName, permissionValue]) => ({ ...acc, [permissionName]: permissionValue }), {})

    const manageApp = combinedUserPermissionsAndAppSchema[USER_ACCESS_PERMISSIONS.manageApp]
    const manageUsers = combinedUserPermissionsAndAppSchema[USER_ACCESS_PERMISSIONS.manageUsers]
    const defaultPermissionsForSelectedUser = { [USER_ACCESS_PERMISSIONS.manageApp]: manageApp, [USER_ACCESS_PERMISSIONS.manageUsers]: manageUsers }
    if (!defaultPermissionsForSelectedUser[USER_ACCESS_PERMISSIONS.manageApp]) {
      defaultPermissionsForSelectedUser[USER_ACCESS_PERMISSIONS.manageApp] = false
    }
    if (!defaultPermissionsForSelectedUser[USER_ACCESS_PERMISSIONS.manageUsers]) {
      defaultPermissionsForSelectedUser[USER_ACCESS_PERMISSIONS.manageUsers] = false
    }

    return { defaultPermissionsForSelectedUser, userPermissionsWithoutDefaults }
  }

  handleCheckbox = (name, value) => {
    this.setState(prevState => ({
      defaultPermissionsForSelectedUser: {
        ...prevState.defaultPermissionsForSelectedUser,
        [name]: value,
      },
    }))
  }

  handleJsonSchema = (name, jsonSchema) => {
    this.setState({
      permissionsForSelectedUser: JSON.parse(JSON.stringify(jsonSchema)),
    })
  }

  handleSubmit = () => {
    const { appId, defaultPermissionsForSelectedUser, permissionsForSelectedUser, user } = this.state
    const allPermissions = {
      ...defaultPermissionsForSelectedUser,
      ...permissionsForSelectedUser,
    }
    // Logic to identify user with prefix '-v | -a | -t | -s' and update the permissions for both `prefix`-username and username.
    if (isUserWithPrefix(user.userId)) {
      this.props.saveUserPermissionsForAppFn(appId, getUserWithoutPrefix(user.userId), allPermissions)
    }
    this.props.saveUserPermissionsForAppFn(appId, user.userId, allPermissions)

    AnalyticsUserAccess.permissionRoleProvided()
    if (!!this.props.permissionsForSelectedUser[USER_ACCESS_PERMISSIONS.manageApp] !== !!defaultPermissionsForSelectedUser[USER_ACCESS_PERMISSIONS.manageApp]) {
      if (defaultPermissionsForSelectedUser[USER_ACCESS_PERMISSIONS.manageApp]) {
        AnalyticsUserAccess.manageAppPermissionProvided()
      } else {
        AnalyticsUserAccess.manageAppPermissionDeleted()
      }
    }

    if (!!this.props.permissionsForSelectedUser[USER_ACCESS_PERMISSIONS.manageUsers] !== !!defaultPermissionsForSelectedUser[USER_ACCESS_PERMISSIONS.manageUsers]) {
      if (defaultPermissionsForSelectedUser[USER_ACCESS_PERMISSIONS.manageUsers]) {
        AnalyticsUserAccess.manageUsersPermissionProvided()
      } else {
        AnalyticsUserAccess.manageUsersPermissionDeleted()
      }
    }
  }

  handleDelete = e => {
    const { openModalFn } = this.props
    const { appId, appName, name, user } = e.target.dataset

    openModalFn(MODAL_KEYS.USER_DELETE_MODAL, {
      ocStyle: 'danger',
      title: 'Delete user',
    }, {
      appId,
      appName,
      name,
      userId: user,
    })
    AnalyticsUserAccess.permissionRoleDeletedPopUp()
  }

  createLabel(label, value, marginLeft, disabled) {
    const { user } = this.state
    return (
      <div key={`${user.userId}-${user.name}-${label}`} style={{ marginLeft }} className='clearfix app-schema-permission-text'>
        <Checkbox inline={false} name={label} onChange={this.handleCheckbox} checked={value} disabled={disabled}>
          {label}
        </Checkbox>
      </div>
    )
  }

  createFooter() {
    const { appId, appName, user } = this.state

    return (
      <div id='app-schema-footer' className='mb-lg pl-lg pr-lg'>
        <Button id='app-schema-approve-button' onClick={this.handleSubmit}>Save</Button>
        <Button
          id='app-schema-delete-button'
          ocStyle='danger'
          className='ml-lg'
          onClick={this.handleDelete}
          disabled={!this.props.hasUserGotPermissions}
          data-name={user.name || user.userId}
          data-user={user.userId}
          data-app-id={appId}
          data-app-name={appName}
        >Delete
        </Button>
      </div>
    )
  }

  renderMessage() {
    const { error, message } = this.props
    if (error) {
      return (
        <Alert className='ml-lg mr-lg' id='app-edit-alert' ocStyle='warning'>
          <p data-testid='app-schema-error' className='pb-md' >{error}</p>
        </Alert>)
    }

    if (message) {
      return (
        <Alert className='ml-lg mr-lg' id='app-edit-alert' ocStyle='success'>
          <p data-testid='app-schema-message' className='pb-md' >{message}</p>
        </Alert>)
    }
  }

  render() {
    const { currentUserId, defaultPermissionsForSelectedUser, permissionsForSelectedUser, user } = this.state
    const { [USER_ACCESS_PERMISSIONS.manageApp]: currentUserManageApp, [USER_ACCESS_PERMISSIONS.manageUsers]: currentUserManageUsers } = this.props.currentUserPermissions
    const { [USER_ACCESS_PERMISSIONS.manageApp]: selectedUserManageApp, [USER_ACCESS_PERMISSIONS.manageUsers]: selectedUserManageUsers } = defaultPermissionsForSelectedUser
    const isCurrentUserSelected = currentUserId === user.userId

    const isCurrentUserSuperUser = currentUserManageApp && currentUserManageUsers
    const isSelectedUserSuperUser = selectedUserManageApp && selectedUserManageUsers
    const isAllowedToChangeDefaultsForUser = isCurrentUserSuperUser || !isSelectedUserSuperUser
    const isNotAllowedToManageApp = isCurrentUserSelected || !currentUserManageApp || !isAllowedToChangeDefaultsForUser
    const isNotAllowedToManageUsers = isCurrentUserSelected || !currentUserManageUsers || !isAllowedToChangeDefaultsForUser
    const isNotAllowedToSeeFooter = isCurrentUserSelected || (isSelectedUserSuperUser && !isCurrentUserSuperUser)

    return (
      <div data-testid='app-schema'>
        <div id='app-schema-content' className='mb-lg'>
          <p className='app-schema-permission-group'>Default Permissions</p>
          { this.createLabel(USER_ACCESS_PERMISSIONS.manageApp, defaultPermissionsForSelectedUser[USER_ACCESS_PERMISSIONS.manageApp], APP_SCHEMA_MARGIN, isNotAllowedToManageApp) }
          { this.createLabel(USER_ACCESS_PERMISSIONS.manageUsers, defaultPermissionsForSelectedUser[USER_ACCESS_PERMISSIONS.manageUsers], APP_SCHEMA_MARGIN, isNotAllowedToManageUsers) }
          <JSONSchema
            className='ml-lg'
            name='complex-json-schema'
            id='complex-json-schema'
            jsonSchema={permissionsForSelectedUser}
            onChange={this.handleJsonSchema}
            isDisabled={isNotAllowedToManageUsers}
            title='Application Permissions'
          />
          { this.renderMessage() }
        </div>
        { isNotAllowedToSeeFooter ? null : this.createFooter() }
      </div>
    )
  }

}
