/* eslint-disable sort-keys */
import axios from 'axios'

import { AUTH, MODAL } from '../constants/ActionTypes'
import { MODAL_KEYS } from '../constants/ModalConfig'

import CookieUtils from './CookieUtils'
import { SESSION } from './SessionUtils'

export default class APIUtils {

  static request(options, dispatch) {
    options.credentials = 'same-origin'

    if (!options.headers) {
      options.headers = {}
    }

    if (!options.headers.Accept) {
      options.headers.Accept = 'application/json'
    }

    if (!options.headers['Content-Type']) {
      options.headers['Content-Type'] = 'application/json'
    }

    // Auth Token
    const token = CookieUtils.get(SESSION.token)
    // check if there is a token and if it is NOT a login call
    if (token && !!options.headers && options.url && !options.url.includes('login')) {
      options.headers.Authorization = `Bearer ${token}`
      options.headers.IdentityId = CookieUtils.get(SESSION.identityId)
    }

    return axios.request(options).catch(error => {
      if (error.response && error.response.status === 401 && options.url.indexOf(__LDAP_HOST__) === -1) {
        dispatch({ type: AUTH.SESSION_EXPIRED })
        dispatch({
          type: MODAL.OPEN_MODAL, payload: {
            modalType: MODAL_KEYS.AUTH_MODAL,
            modalProps: {
              ocSize: 'sm',
              title: 'Session Expired',
            },
          },
        })
      } else {
        return error
      }
    })
  }

  static get(dispatch, url, params) {
    return APIUtils.request({
      url,
      method: 'GET',
      dataType: 'json',
      params,
    }, dispatch)
  }

  static post(dispatch, url, data, options) {
    return APIUtils.request({
      url,
      method: 'POST',
      dataType: 'json',
      data,
      ...options,
    }, dispatch)
  }

  static put(dispatch, url, data) {
    return APIUtils.request({
      url,
      method: 'PUT',
      dataType: 'json',
      data,
    }, dispatch)
  }

  static delete(dispatch, url, data) {
    return APIUtils.request({
      url,
      method: 'DELETE',
      data,
    }, dispatch)
  }

  static searchUsers(param) {
    return APIUtils.request({
      url: `${__LDAP_HOST__}/users?query=${param}`,
      method: 'GET',
      dataType: 'json',
    })
  }

}
