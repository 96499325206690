import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { getAppPath } from '@expediapartnersolutions/app-hub-components'
import { Alert, Card, Tag } from '@expediapartnersolutions/ocean'

// { appId: message}
const deprecatedApps = [
  'bulkCancelApp',
  'relevancy-file',
  'Floo',
]

const movedApps = [
  'analytics',
  'CommissionsControlCenter',
  'dobby',
  'eac-statements',
  'epsstatus',
  'marketing-fee-statements',
  'sms-app',
]

const noticeMessage = isMoved => (
  <React.Fragment>
    <p>{isMoved ? 'Visit the new partner portal to access this.' : 'This app is no longer available'}</p>
    <Tag ocSize='sm' className='mt-md' style={{ cursor: 'pointer' }}>
      {isMoved ? 'This has moved' : 'Deprecated'}
    </Tag>
  </React.Fragment>
)

const Launchpad = ({ appConfig }) => (
  <div id='dashboard-cards' className='card-wrapper-grid mt-lg'>
    <div className='console-decommission-banner'>
      <Alert ocStyle='warning' title='Console has been decommissioned.'>
        <span>
          You can continue to use your apps in Portal, to do so click <a href={__PORTAL_CONSOLE_EMULATOR__}>here</a>.
        </span><br/>
        <span>
          If you do not have access to the Console Emulator, please join the following <a href={__CONSOLE_EMULATOR_SG__}>Security Group</a>: <strong>eps-portal-console-emulator</strong>.
        </span>
      </Alert>
    </div>
    <Card
      ComponentType={Link}
      id='user-access-card'
      title='User Access'
      to='/apps-manager'
    >
      <p>Create and manage application permissions.</p>
    </Card>
    {appConfig
      .sort((a, b) => a.summary.localeCompare(b.summary))
      .map(({ description, name: appId, summary: title }) => {
        const isMoved = movedApps.includes(appId)
        const isDeprecated = deprecatedApps.includes(appId)

        return (
          <Card
            ComponentType={Link}
            key={appId}
            id={`${appId}-card`}
            title={title}
            to={
              isMoved
                ? '//portal.expediapartnersolutions.com/sign-in'
                : getAppPath(appId)
            }
            className={isMoved || isDeprecated ? 'deprecated' : null}
            target={isMoved && '_blank'}
          >
            {isMoved || isDeprecated ? null : <p>{description}</p>}
            {isMoved && noticeMessage(true)}
            {isDeprecated && noticeMessage(false)}
          </Card>
        )
      })}
  </div>
)

Launchpad.propTypes = {
  appConfig: PropTypes.array.isRequired,
}

export default Launchpad
