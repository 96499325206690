import keyMirrorPfx from 'keymirrorprefix'

import AuthModal from '../components/AuthModal'
import UserDeleteModal from '../apps/AppsManager/containers/UserDeleteModal'

export const MODAL_KEYS = keyMirrorPfx(null, {
  AUTH_MODAL: null,
  USER_DELETE_MODAL: null,
})

export const MODAL_COMPONENTS = {
  AUTH_MODAL: AuthModal,
  USER_DELETE_MODAL: UserDeleteModal,
}
