import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, FontIcon, Loading, Row } from '@expediapartnersolutions/ocean'

import AnalyticsUserAccess from '../../../utils/analyticsUserAccess'

import { AppSchema } from './AppSchema'
import LdapUserSearchTypeahead from './LdapUserSearchTypeahead'

export class AppUsers extends React.Component {

  static propTypes = {
    app: PropTypes.object,
    appPermissionsSchema: PropTypes.object,
    appUsers: PropTypes.array,
    deletedUserId: PropTypes.string,
    error: PropTypes.string,
    getUserPermissionsForAppFn: PropTypes.func.isRequired,
    getUsersAndSchemaForAppFn: PropTypes.func.isRequired,
    handleExitFn: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    message: PropTypes.string,
    onUnmountFn: PropTypes.func.isRequired,
    openModalFn: PropTypes.func.isRequired,
    permissionsForSelectedUser: PropTypes.object,
    saveUserPermissionsForAppFn: PropTypes.func.isRequired,
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedUser: {},
      typeaheadUser: null,
    }
  }

  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillMount() {
    this.props.getUsersAndSchemaForAppFn(this.props.app)
  }

  componentWillUnmount() {
    this.props.onUnmountFn()
  }

  handleSelection = suggestion => {
    AnalyticsUserAccess.userSearched()
    const { app } = this.props
    this.setState({
      selectedUser: {
        userId: suggestion.userName,
        userName: suggestion.userName,
      },
      typeaheadUser: {
        name: suggestion.userName,
        userId: suggestion.userName,
      },
    })
    this.props.getUserPermissionsForAppFn(app.name, suggestion.userName)
  }

  handleUserClick = e => {
    const { userid, username } = e.currentTarget.dataset
    const { app, deletedUserId } = this.props
    const { typeaheadUser } = this.state
    if (typeaheadUser && typeaheadUser.userId === deletedUserId) {
      this.setState({
        selectedUser: {
          userId: userid,
          userName: username,
        },
        typeaheadUser: null,
      })
    } else {
      this.setState({
        selectedUser: {
          userId: userid,
          userName: username,
        },
      })
    }
    this.props.getUserPermissionsForAppFn(app.name, userid)
  }

  renderUser(index, userId, userName, isSelected) {
    return (
      <li key={index} data-userid={userId} data-username={userName} className={`pb-lg app-users-list-item ${isSelected ? 'app-users-active-user' : ''}`} onClick={this.handleUserClick}>
        {userName || userId}
        {
          isSelected && <FontIcon icon='chevron-right' style={{ fontSize: '16px', marginLeft: 'auto' }}/>
        }
      </li>
    )
  }

  renderAppSchema() {
    const { app, appPermissionsSchema, error, isLoading, message, openModalFn, permissionsForSelectedUser, saveUserPermissionsForAppFn, user } = this.props
    const { selectedUser } = this.state
    if (isLoading) {
      return <Loading id='app-users-page-loading' data-testid='app-users-page-loading' label='Loading permissions...'/>
    }

    if (permissionsForSelectedUser) {
      const userDetails = {
        name: selectedUser.userName || selectedUser.userId,
        userId: selectedUser.userId,
      }

      // delete button should be disabled if you select a user that doesn't have permissions at all
      const hasUserGotPermissions = permissionsForSelectedUser && Object.keys(permissionsForSelectedUser).length > 0

      return (
        <AppSchema
          appSchema={appPermissionsSchema}
          currentUserId={user.username}
          currentUserPermissions={user.permissions[app.name]}
          permissionsForSelectedUser={permissionsForSelectedUser}
          saveUserPermissionsForAppFn={saveUserPermissionsForAppFn}
          appId={app.name}
          appName={app.summary}
          user={userDetails}
          error={error}
          message={message}
          openModalFn={openModalFn}
          hasUserGotPermissions={hasUserGotPermissions}
        />
      )
    }
  }

  renderUserList() {
    const { appUsers } = this.props
    const { selectedUser, typeaheadUser } = this.state

    if (appUsers && appUsers.length > 0) {
      return appUsers.filter(user => !typeaheadUser || user.userId !== typeaheadUser.userId)
        .map((user, index) => {
          const isSelected = selectedUser.userId === user.userId
          const name = (user.details && user.details.name) || user.userId
          return this.renderUser(index, user.userId, name, isSelected)
        })
    }
  }

  renderTypeaheadUser() {
    const { selectedUser, typeaheadUser } = this.state

    if (typeaheadUser && (!this.props.deletedUserId || typeaheadUser.userId !== this.props.deletedUserId)) {
      const isSelected = typeaheadUser.userId === selectedUser.userId
      return this.renderUser(typeaheadUser.userId, typeaheadUser.userId, typeaheadUser.name, isSelected)
    }
  }

  render() {
    const { app, handleExitFn } = this.props

    return (
      <div id='app-users-page' data-testid='app-users-page'>
        <Row>
          <Col xs={6} sm={8} md={10}>
            <h3 className='mt-n mb-md'>{app.summary}</h3>
          </Col>
          <Col xs={6} sm={4} md={2} className='text-right'>
            <Button id='app-users-page-exit' data-testid='app-users-page-exit' onClick={handleExitFn} ocStyle='cancel'>Go back</Button>
          </Col>
        </Row>
        <hr/>
        <Row className='ml-n'>
          <h4 className='text-black mt-n'><b>Setup users and permissions</b></h4>
        </Row>
        <div id='app-users-page-header' data-testid='app-users-page-header' className='app-users pt-md mt-md'>
          <div id='app-users-page-user-header' className='app-users-header'>
            <p className='app-users-text'>Users</p>
          </div>
          <div id='app-users-page-permissions-header' className='app-permissions-header'>
            <p className='app-users-text'>Permissions</p>
          </div>
        </div>
        <div id='app-users-page-content' data-testid='app-users-page-content' className='app-users'>
          <div id='app-users-page-user-content' className='app-users-content'>
            <LdapUserSearchTypeahead label='Add User' onSelection={this.handleSelection} className='app-users-content-ldap'/>
            <ul id='app-users-page-list' className='app-users-list'>
              {this.renderTypeaheadUser()}
              {this.renderUserList()}
            </ul>
          </div>
          <div id='app-users-page-permissions-content' className='app-permissions-content'>
            {this.renderAppSchema()}
          </div>
        </div>
      </div>
    )
  }

}
