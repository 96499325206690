import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Alert, Checkbox, FormControl } from '@expediapartnersolutions/ocean'

import TagsInput from './TagsInput'

export default class JSONSchema extends React.Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    jsonSchema: PropTypes.object.isRequired,
    margin: PropTypes.number,
    name: PropTypes.string.isRequired,
    ocSize: PropTypes.oneOf(['xs', 'sm', 'lg']),
    ocStyle: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'cancel', 'upload', 'success', 'info', 'warning', 'danger', 'link']),
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  }

  static defaultProps = {
    isDisabled: false,
    margin: 15,
    ocSize: 'sm',
    ocStyle: 'secondary',
  }

  constructor(props) {
    super(props)
    this.state = props.jsonSchema
  }

  /**
   * Data: { inner1: { inner2: { write: false }}}
   * Input: inner1>inner2>write, true
   * Output: { inner1: { inner2: { write: true }}}
   */
  replaceElementInSchema(stringLocation, newValue) {
    if (stringLocation && typeof stringLocation === 'string') {
      const pathArray = stringLocation.split('>')
      const jsonSchema = JSON.parse(JSON.stringify(this.state))
      if (pathArray.length === 1) {
        jsonSchema[stringLocation] = newValue
      } else {
        const propertyToBeChanged = pathArray.pop()
        const referenceObject = pathArray.reduce((object, key) => object[key], jsonSchema)
        referenceObject[propertyToBeChanged] = newValue
      }
      return jsonSchema
    }
  }

  handleChange = (name, value) => {
    const { name: jsonSchemaName, onChange } = this.props
    const newJsonSchema = this.replaceElementInSchema(name, value)

    this.setState(newJsonSchema, () => {
      onChange(jsonSchemaName, newJsonSchema)
    })
  }

  traverseSchema(jsonSchema, renderedComponents, isDisabled, parent) {
    const marginLeft = this.props.margin
    for (const key in jsonSchema) {
      const newParent = parent ? `${parent}>${key}` : key
      if (typeof jsonSchema[key] === 'boolean') {
        renderedComponents.push(this.createCheckbox(jsonSchema, key, marginLeft, isDisabled, newParent))
      } else if (typeof jsonSchema[key] === 'string') {
        renderedComponents.push(this.createInput(jsonSchema, key, marginLeft, isDisabled, newParent))
      } else if (Array.isArray(jsonSchema[key]) && jsonSchema[key].every(element => typeof element === 'string')) {
        renderedComponents.push(this.createArrayInput(jsonSchema, key, marginLeft, isDisabled, newParent))
      } else if (typeof jsonSchema[key] === 'object' && jsonSchema[key] !== null) {
        renderedComponents.push(this.createSchemaObject(key, jsonSchema[key], marginLeft, isDisabled, newParent))
      }
    }
  }

  createSchemaObject(label, jsonSchema, marginLeft, isDisabled, parent) {
    const objectComponents = []
    const { id } = this.props
    this.traverseSchema(jsonSchema, objectComponents, isDisabled, parent)

    return (
      <Alert key={`${id}-${label}-${parent}-object`} style={{ marginLeft }} className='json-schema json-schema--alert' ocStyle='info' title={label}>
        {objectComponents}
      </Alert>
    )
  }

  createCheckbox(schema, label, marginLeft, isDisabled, parent) {
    const { id } = this.props
    const checked = schema ? schema[label] : false
    return (
      <div key={`${id}-${label}-${parent}-check`} style={{ marginLeft }} className='clearfix json-schema text-primary'>
        <Checkbox className='mb-md' inline={false} name={parent} onChange={this.handleChange} checked={checked} disabled={isDisabled}>
          {label}
        </Checkbox>
      </div>
    )
  }

  createInput(schema, label, marginLeft, isDisabled, parent) {
    const { id } = this.props
    return (
      <div key={`${id}-${label}-${parent}-input`} style={{ marginLeft }} className={`clearfix json-schema--input-display ${isDisabled ? 'json-schema--disabled' : null}`}>
        <FormControl className='mb-md' name={parent} label={label} onChange={this.handleChange} defaultValue={schema[label]} disabled={isDisabled}/>
      </div>
    )
  }

  createArrayInput(schema, label, marginLeft, isDisabled, parent) {
    const { id, ocSize, ocStyle } = this.props
    const key = `${id}-${label}-${parent}`
    return (
      <div key={`${key}-input`} style={{ marginLeft }} className='clearfix ml-n mb-sm'>
        <TagsInput
          name={parent}
          key={`${key}-tag-input`}
          id={`${key}-tag-input`}
          style={{ marginLeft }}
          tags={schema[label]}
          onChange={this.handleChange}
          ocSize={ocSize}
          ocStyle={ocStyle}
          isDisabled={isDisabled}
          label={`${label} (type and press Enter)`}
        />
      </div>
    )
  }

  render() {
    const currentJsonSchema = this.state
    const { className, id, isDisabled, jsonSchema, margin, name, ocSize, ocStyle, onChange, ...other } = this.props
    const jsonSchemaClass = classNames('jsonschema', className)

    return (
      <div id={id} className={jsonSchemaClass} {...other}>
        {this.createSchemaObject(this.props.title, currentJsonSchema, 0, isDisabled)}
      </div>
    )
  }

}
