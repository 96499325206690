/* eslint-disable sort-keys */
import keyMirrorPfx from 'keymirrorprefix'

export const APPS_MANAGER = keyMirrorPfx(null, {
  ALL_APPS_REQUEST: null,
  ALL_APPS_SUCCESS: null,
  ALL_APPS_FAILURE: null,

  CREATE_APP_REQUEST: null,
  CREATE_APP_SUCCESS: null,
  CREATE_APP_FAILURE: null,
  CREATE_UPDATE_APP_RESET: null,
  CLEAR_UPDATING_APP: null,

  DELETE_USER_PERMISSIONS: null,
  DELETE_USER_PERMISSIONS_SUCCESS: null,
  DELETE_USER_PERMISSIONS_FAILURE: null,

  GET_USERS_AND_SCHEMA_FOR_APP: null,
  GET_USERS_AND_SCHEMA_FOR_APP_SUCCESS: null,
  GET_USERS_AND_SCHEMA_FOR_APP_FAILURE: null,

  GET_APP_SCHEMA: null,
  GET_APP_SCHEMA_SUCCESS: null,
  GET_APP_SCHEMA_FAILURE: null,

  GET_USER_PERMISSIONS: null,
  GET_USER_PERMISSIONS_SUCCESS: null,
  GET_USER_PERMISSIONS_FAILURE: null,

  SAVE_USER_PERMISSIONS: null,
  SAVE_USER_PERMISSIONS_SUCCESS: null,
  SAVE_USER_PERMISSIONS_FAILURE: null,

  UPDATE_APP_REQUEST: null,
  UPDATE_APP_SUCCESS: null,
  UPDATE_APP_FAILURE: null,
  UPDATE_APP_RESET: null,
})

export const AUTH = keyMirrorPfx(null, {
  LOGIN_REQUEST: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAILURE: null,

  REFRESH_PERMISSIONS_REQUEST: null,
  REFRESH_PERMISSIONS_SUCCESS: null,
  REFRESH_PERMISSIONS_FAILURE: null,

  LOGOUT: null,
  SESSION_EXPIRED: null,
})

export const MODAL = keyMirrorPfx(null, {
  OPEN_MODAL: null,
  CLOSE_MODAL: null,
})
