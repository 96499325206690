import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Typeahead } from '@expediapartnersolutions/ocean'

import APIUtils from '../../../utils/APIUtils'

const LdapUserSearchTypeahead = ({ className, label, onClear, onSelection }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [ldapUsers, setLdapUsers] = useState([])

  const getSuggestionValue = useCallback(suggestion => `${suggestion.displayName} (${suggestion.userName})`, [])
  const renderSuggestion = useCallback(suggestion => <span>{suggestion.displayName} ({suggestion.userName})</span>, [])

  const handleSearch = useCallback((_inputName, value) => {
    setIsLoading(true)
    APIUtils.searchUsers(value).then(({ data }) => {
      setLdapUsers(data.results)
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  const handleSelection = useCallback((_inputName, selection) => {
    onSelection(selection)
  }, [onSelection])

  return (
    <Typeahead
      className={`app-users-typeahead ${className}`}
      data-testid='ldap-users-typeahead'
      filterBy={({ userName, displayName }, searchValue) => userName.toLowerCase().includes(searchValue) || displayName.toLowerCase().includes(searchValue)}
      id='ldap-users-type-ahead'
      isLoading={isLoading}
      label={label}
      minSearch={3}
      name='ldapUsersSearch'
      ocSize='lg'
      onClear={onClear}
      onSearch={handleSearch}
      onSelection={handleSelection}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      suggestions={ldapUsers}
    />
  )
}

LdapUserSearchTypeahead.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClear: PropTypes.func,
  onSelection: PropTypes.func.isRequired,
}

export default LdapUserSearchTypeahead
