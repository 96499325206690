import { APPS_MANAGER } from '../../constants/ActionTypes'
import { USER_ACCESS_PERMISSIONS } from '../../constants/Permissions'
import APIUtils from '../../utils/APIUtils'

const { manageApp, manageUsers } = USER_ACCESS_PERMISSIONS

export const clearAppState = () => dispatch => dispatch({ type: APPS_MANAGER.CREATE_UPDATE_APP_RESET })

export const clearUpdatingApp = () => dispatch => dispatch({ type: APPS_MANAGER.CLEAR_UPDATING_APP })

const isManageAppOrManageUser = permission => [manageApp, manageUsers].includes(permission)

function getAppSchemaApiCall(dispatch, appId) {
  return APIUtils.get(dispatch, `${__PERMISSIONS_HOST__}/application/${appId}`)
    .then(response => {
      /**
       *  In permissions V2, we get the schema as an array of permissions, which we assume to be 'false' by default
       *  If the deprecatedSchema property is present, it must be the deprecated permissions (which can eventually be removed)
       *  deprecated permissions are those which still use a json blob, and so are not required to be transformed
       *  we also need to make sure manageApp and manageUser are not rendered (they are default)
       */
      if (response.data.deprecatedSchema) {
        const permissionsWithoutDefaults = Object.entries(response.data.deprecatedSchema)
          .filter(([permissionName]) => !isManageAppOrManageUser(permissionName))
          .reduce((schemaWithoutDefaults, [permissionName, defaultValue]) => ({ ...schemaWithoutDefaults, [permissionName]: defaultValue }), {})
        return { schema: permissionsWithoutDefaults, isDeprecatedSchema: true }
      }
      const schemaAsObject = response.data.permissionSchema
        .filter(permission => !isManageAppOrManageUser(permission))
        .reduce((appSchema, permission) => ({
          ...appSchema,
          [permission]: false,
        }), {})
      return { schema: schemaAsObject, isDeprecatedSchema: false }
    })
}

export function getAppSchema(app) {
  return dispatch => {
    dispatch({ type: APPS_MANAGER.CREATE_UPDATE_APP_RESET })
    dispatch({ type: APPS_MANAGER.GET_APP_SCHEMA })
    return getAppSchemaApiCall(dispatch, app.name)
      .then(permissionsAsObject => dispatch({
        type: APPS_MANAGER.GET_APP_SCHEMA_SUCCESS,
        payload: { ...app, appSchema: permissionsAsObject.schema, isDeprecatedSchema: permissionsAsObject.isDeprecatedSchema },
      }))
      .catch(error => dispatch({
        type: APPS_MANAGER.GET_APP_SCHEMA_FAILURE,
        payload: error,
      }))
  }
}

function createAppsMetadataApp(dispatch, app) {
  const appsMetadataCreateObject = {
    name: app.appId,
    summary: app.appSummary,
    description: app.description,
    hubReference: 'console',
  }
  return APIUtils.post(dispatch, `${__APPS_METADATA_HOST__}/application`, appsMetadataCreateObject)
}

function updateAppsMetadataApp(dispatch, app) {
  const appsMetadataUpdateObject = {
    summary: app.appSummary,
    description: app.description,
    hubReference: 'console',
  }
  return APIUtils.put(dispatch, `${__APPS_METADATA_HOST__}/application/${app.appId}`, appsMetadataUpdateObject)
}

/**
 * A deprecated app is one which has its permissions still saved as a JSON blob rather than an array of strings
 */
function getCreateOrUpdatePermissionsAppBody(app, isDeprecatedApp) {
  app.appSchema[USER_ACCESS_PERMISSIONS.manageApp] = false
  app.appSchema[USER_ACCESS_PERMISSIONS.manageUsers] = false
  if (isDeprecatedApp) {
    return {
      name: app.appId,
      deprecatedPermissionSchema: app.appSchema,
    }
  }
  return {
    name: app.appId,
    permissionSchema: Object.keys(app.appSchema),
  }
}

function createApplication(dispatch, app) {
  const permissionsCreateObject = getCreateOrUpdatePermissionsAppBody(app, false)
  return APIUtils.post(dispatch, `${__PERMISSIONS_HOST__}/application`, permissionsCreateObject)
}

function updateAppPermissions(dispatch, app, isDeprecatedApp) {
  const permissionsUpdateObject = getCreateOrUpdatePermissionsAppBody(app, isDeprecatedApp)
  return APIUtils.put(dispatch, `${__PERMISSIONS_HOST__}/application/${app.appId}`, permissionsUpdateObject)
}

export function createApp(app, callback) {
  return dispatch => {
    dispatch({ type: APPS_MANAGER.CREATE_APP_REQUEST })
    return Promise.all([createApplication(dispatch, app), createAppsMetadataApp(dispatch, app)])
      .then(() => {
        dispatch({ type: APPS_MANAGER.CREATE_APP_SUCCESS })
        callback()
      })
      .catch(error => dispatch({ type: APPS_MANAGER.CREATE_APP_FAILURE, payload: error }))
  }
}

const getAppsMetadata = dispatch => APIUtils.get(dispatch, `${__APPS_METADATA_HOST__}/application`)

export function listAppPermissions() {
  return dispatch => {
    dispatch({ type: APPS_MANAGER.ALL_APPS_REQUEST })
    return getAppsMetadata(dispatch)
      .then(appsMetadataResponse => {
        const appsMetadata = appsMetadataResponse.data || {}
        const appsMetadataArray = Object.values(appsMetadata)
        dispatch({ type: APPS_MANAGER.ALL_APPS_SUCCESS, payload: appsMetadataArray })
      })
      .catch(error => dispatch({ type: APPS_MANAGER.ALL_APPS_FAILURE, payload: error }))
  }
}

const usersPermissionsFromAppApiResponse = ({ data }, appId) => Object.entries(data)
  .map(([userId, permissions]) => ({
    appId,
    details: { name: userId },
    permissions,
    userId,
  }))

export function getUsersFromApp(app) {
  return dispatch => {
    dispatch({ type: APPS_MANAGER.SEARCH_USERS_FOR_APP })
    return APIUtils.get(dispatch, `${__PERMISSIONS_HOST__}/tools/application/${app.name}`)
      .then(response => dispatch({
        type: APPS_MANAGER.SEARCH_USERS_FOR_APP_SUCCESS,
        payload: usersPermissionsFromAppApiResponse(response, app.name),
      }))
      .catch(error => dispatch({
        type: APPS_MANAGER.SEARCH_USERS_FOR_APP_FAILURE,
        payload: error,
      }))
  }
}

const getUsersForApp = (dispatch, app) => APIUtils.get(dispatch, `${__APPS_METADATA_HOST__}/permissions/application/${app.name}`)

export function getUsersAndSchemaForApp(app) {
  return dispatch => {
    dispatch({ type: APPS_MANAGER.GET_USERS_AND_SCHEMA_FOR_APP })
    return Promise.all([getUsersForApp(dispatch, app), getAppSchemaApiCall(dispatch, app.name)])
      .then(([usersResponse, appSchemaResponse]) => dispatch({
        type: APPS_MANAGER.GET_USERS_AND_SCHEMA_FOR_APP_SUCCESS,
        payload: { users: usersPermissionsFromAppApiResponse(usersResponse, app.name), schema: appSchemaResponse.schema },
      }))
      .catch(error => dispatch({
        type: APPS_MANAGER.GET_USERS_AND_SCHEMA_FOR_APP_FAILURE,
        payload: error,
      }))
  }
}

export function getUserPermissionsForApp(appId, userId) {
  return dispatch => {
    dispatch({ type: APPS_MANAGER.GET_USER_PERMISSIONS })
    return APIUtils.get(dispatch, `${__PERMISSIONS_HOST__}/application/${appId}/user/${userId}?excludeGroups=false`)
      .then(response => dispatch({
        type: APPS_MANAGER.GET_USER_PERMISSIONS_SUCCESS,
        payload: response.data,
      }))
      .catch(error => dispatch({
        type: APPS_MANAGER.GET_USER_PERMISSIONS_FAILURE,
        payload: error,
      }))
  }
}

export function deleteUser(appId, username) {
  return dispatch => {
    dispatch({ type: APPS_MANAGER.DELETE_USER_PERMISSIONS })
    return APIUtils.delete(dispatch, `${__PERMISSIONS_HOST__}/application/${appId}/user/${username}`)
      .then(() => dispatch({ type: APPS_MANAGER.DELETE_USER_PERMISSIONS_SUCCESS, payload: { userId: username } }))
      .catch(error => dispatch({ type: APPS_MANAGER.DELETE_USER_PERMISSIONS_FAILURE, payload: error }))
  }
}

export function saveUserPermissionsForApp(appId, username, permissions) {
  return dispatch => {
    dispatch({ type: APPS_MANAGER.SAVE_USER_PERMISSIONS })
    return APIUtils.put(dispatch, `${__PERMISSIONS_HOST__}/application/${appId}/user/${username}`, permissions)
      .then(() => dispatch({ type: APPS_MANAGER.SAVE_USER_PERMISSIONS_SUCCESS, payload: { appId, details: { name: username }, userId: username, permissions } }))
      .catch(error => dispatch({ type: APPS_MANAGER.SAVE_USER_PERMISSIONS_FAILURE, payload: error }))
  }
}

export function updateApp(app, isDeprecatedApp, callback) {
  return async dispatch => {
    dispatch({ type: APPS_MANAGER.UPDATE_APP_REQUEST })
    try {
      await Promise.all([updateAppPermissions(dispatch, app, isDeprecatedApp), updateAppsMetadataApp(dispatch, app)])
      dispatch({ type: APPS_MANAGER.UPDATE_APP_SUCCESS })
      callback()
    } catch (error) {
      dispatch({ type: APPS_MANAGER.UPDATE_APP_FAILURE, payload: error })
    }
  }
}
