export default class CookieUtils {

  static get(cname) {
    const name = `${cname}=`
    const ca = document.cookie.split(';')
    const cookie = ca.map(c => c.trim()).find(c => c.indexOf(name) === 0)

    return cookie ? cookie.substring(name.length, cookie.length) : ''
  }

  static set(key, value, expires, domain = window.location.hostname) {
    let cookieString = `${key}=${value}`
    if (domain) {
      cookieString += `; domain=${domain}`
    }
    if (expires) {
      cookieString += `; expires=${expires.toUTCString()}`
    }
    document.cookie = cookieString
  }

  static expire(key) {
    document.cookie = `${key}='balloon'; expires=Thu, 01 Jan 1970 00:00:00 UTC`
  }

}
