import React from 'react'
import PropTypes from 'prop-types'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'
import { RequestStatus } from '@expediapartnersolutions/common-ui-lib'
import { Button, Col, DataLabel, FormControl, Row } from '@expediapartnersolutions/ocean'

import AnalyticsUserAccess from '../../../utils/analyticsUserAccess'

export class AppSettings extends React.Component {

  static propTypes = {
    app: PropTypes.object,
    createAppFn: PropTypes.func,
    error: PropTypes.string,
    handleExitFn: PropTypes.func.isRequired,
    isDeprecatedAppSchema: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    onSuccessfulChangeFn: PropTypes.func.isRequired,
    onUnmountFn: PropTypes.func.isRequired,
    updateAppFn: PropTypes.func,
    user: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    const errors = {
      appId: null,
      appSchema: null,
      appSummary: null,
      description: null,
    }

    const errorMessages = {
      appId: 'App Id is required and must not contain spaces',
      appSchema: 'App Schema is required',
      appSummary: 'App Name is required',
      description: 'Description is required',
    }

    if (props.app) {
      this.state = {
        ...props.app,
        appId: props.app.name,
        appSchema: props.app.appSchema,
        appSummary: props.app.summary,
        description: props.app.description,
        errorMessages,
        errors,
      }
    } else {
      this.state = {
        appId: null,
        appSchema: null,
        appSummary: null,
        description: null,
        errorMessages,
        errors,
      }
    }
  }

  componentWillUnmount() {
    this.props.onUnmountFn()
  }

  handleChange = (name, value) => {
    const { errorMessages, errors } = this.state
    let newError = {}

    switch (name) {
    case 'appId': {
      const isAppIdValid = this.isAppIdValid(value)
      newError = {
        appId: isAppIdValid ? null : errorMessages.appId,
      }
      break
    }
    default:
      newError = {
        [name]: value ? null : errorMessages[name],
      }
      break
    }

    const newErrorObj = {
      ...errors,
      ...newError,
    }

    this.setState({
      errors: newErrorObj,
      [name]: value,
    })
  }

  handleJSONChange = value => {
    if (value.jsObject) {
      this.setState({ appSchema: value.jsObject })
    } else {
      this.setState({ appSchema: null })
    }
  }

  handleSubmit = e => {
    AnalyticsUserAccess.saveYourNewApp()
    e.preventDefault()
    const { app, createAppFn, isDeprecatedAppSchema, onSuccessfulChangeFn, updateAppFn, user } = this.props
    const { appId, appSchema, appSummary, createdBy, description } = this.state

    const newApp = {
      appId,
      appSchema,
      appSummary,
      createdBy,
      description,
    }

    if (this.validate(newApp)) {
      newApp.username = user.username
      if (app) {
        updateAppFn(newApp, isDeprecatedAppSchema, onSuccessfulChangeFn)
      } else {
        newApp.createdBy = user.username
        createAppFn(newApp, onSuccessfulChangeFn)
      }
    }
  }

  isAppIdValid(appId) {
    return appId && appId.indexOf(' ') < 0
  }

  validate(app) {
    const { errorMessages } = this.state
    const isAppIdValid = this.isAppIdValid(app.appId)
    const newErrors = {
      appId: isAppIdValid ? null : errorMessages.appId,
      appSchema: app.appSchema ? null : errorMessages.appSchema,
      appSummary: app.appSummary ? null : errorMessages.appSummary,
      description: app.description ? null : errorMessages.description,
    }

    this.setState({
      errors: newErrors,
    })
    return isAppIdValid && app.appSchema && app.appSummary && app.description
  }

  renderJsonInputError() {
    const { errors } = this.state
    if (errors.appSchema) {
      return <p id='app-schema-error' className='form-error'>{errors.appSchema}</p>
    }
  }

  renderCreateForm() {
    const { app } = this.props
    const { appId, appSummary, description, errors } = this.state

    let initialSchema = {
      permission1: true,
      permission2: false,
      permission3: false,
    }

    if (app) {
      initialSchema = app.appSchema
    }

    const formProps = {
      onChange: this.handleChange,
      required: true,
    }

    return (
      <form id='create-app-form' data-testid='create-app-form' onSubmit={this.handleSubmit}>
        <Row className='ml-n'>
          <h4 className='text-black mt-n'><b>App Settings</b></h4>
        </Row>
        <Row className='mt-lg'>
          <Col sm={6}>
            <DataLabel label='App name'>The name of the application</DataLabel>
            <FormControl id='app-name' data-testid='app-name' label='App name' name='appSummary' error={errors.appSummary} defaultValue={appSummary || ''} {...formProps}/>
          </Col>
          <Col sm={6}>
            <DataLabel label='App ID'>The unique ID of the application</DataLabel>
            <FormControl id='app-id' data-testid='app-id' label='App ID' name='appId' error={errors.appId} defaultValue={appId || ''} disabled={!!app} {...formProps}/>
          </Col>
        </Row>
        <Row className='mt-lg'>
          <Col sm={12} className='ml-sm'>
            <DataLabel label='App description'>A description for your application</DataLabel>
            <FormControl id='app-description' data-testid='app-description' label='App Description' name='description' error={errors.description} defaultValue={description || ''} multiline rows={4} {...formProps}/>
          </Col>
        </Row>
        <Row className='mt-lg'>
          <Col sm={12} className='ml-sm'>
            <DataLabel label='App permissions schema'>This is the schema for your application permissions</DataLabel>
            <JSONInput
              id='app-schema'
              placeholder={initialSchema}
              locale={locale}
              onChange={this.handleJSONChange}
              width='100%'
              height='500px'
            />
            {this.renderJsonInputError()}
          </Col>
        </Row>
        <Row className='mt-lg ml-sm'>
          <Button id='app-settings-submit' data-testid='app-settings-submit' type='submit'>Save your new app</Button>
        </Row>
      </form>
    )
  }

  renderTitle() {
    const { app } = this.props

    return <h3 className='mt-n mb-md'>{app ? app.appSummary : 'Add new app permissions'}</h3>
  }

  render() {
    const { error, handleExitFn, isLoading } = this.props

    return (
      <div id='app-settings' data-testid='app-settings'>
        <Row>
          <Col xs={6} sm={8} md={10}>
            {this.renderTitle()}
          </Col>
          <Col xs={6} sm={4} md={2} className='text-right'>
            <Button id='app-settings-exit' onClick={handleExitFn} ocStyle='cancel'>Go back</Button>
          </Col>
        </Row>
        <hr/>
        <RequestStatus id='app-settings-content' error={error} isLoading={isLoading}>
          {this.renderCreateForm()}
        </RequestStatus>
      </div>
    )
  }

}
