import history from 'history/browser'

import { MODAL } from '../../constants/ActionTypes'

export function closeModal() {
  return dispatch => {
    dispatch({ type: MODAL.CLOSE_MODAL })
    history.replace({
      search: '',
    })
  }
}

export function openModal(modal, props = {}, contentProps = {}) {
  return dispatch => {
    dispatch({ type: MODAL.OPEN_MODAL, payload: {
      modalContentProps: contentProps,
      modalProps: props,
      modalType: modal,
    } })
  }
}
