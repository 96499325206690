import React from 'react'
import { Button } from '@expediapartnersolutions/ocean'

const AuthModal = () => (
  <div>
    <p className='mb-lg'>Your session has expired. Please login again to continue.</p>
    <Button href={__SSO_HOST__}>Log in via Okta</Button>
  </div>
)

export default AuthModal
