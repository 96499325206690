import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { RequestStatus } from '@expediapartnersolutions/common-ui-lib'
import { Button, Loading } from '@expediapartnersolutions/ocean'

import { closeModal, deleteUser } from '../../../actions'
import AnalyticsUserAccess from '../../../utils/analyticsUserAccess'

export class UserDeleteModal extends React.Component {

  static propTypes = {
    appId: PropTypes.string.isRequired,
    appName: PropTypes.string.isRequired,
    closeModalFn: PropTypes.func.isRequired,
    deleteUserFn: PropTypes.func.isRequired,
    deletedUserId: PropTypes.string,
    error: PropTypes.string,
    isLoadingModal: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
  }

  closeModal = () => {
    this.props.closeModalFn()
    AnalyticsUserAccess.permissionRoleDeleteCancellation()
  }

  submit = () => {
    const { appId, deleteUserFn, userId } = this.props
    deleteUserFn(appId, userId)
    AnalyticsUserAccess.permissionRoleDeleteConfirmed()
  }

  renderContent() {
    const { appName, deletedUserId, error, isLoadingModal, name } = this.props

    if (isLoadingModal) {
      return <Loading id='delete-user-loading'/>
    }

    if (error) {
      return <p id='delete-user-change-error'>{error}</p>
    }

    if (deletedUserId) {
      return (
        <div className='mt-md mb-md' id='delete-user-success'>
          <p id='delete-user-success-text'>{`User ${name} has been deleted`}</p>
        </div>
      )
    }

    return (
      <div className='mt-md mb-md text-black' id='delete-user-modal' data-testid='delete-user-modal'>
        <p id='delete-user-confirmation' data-testid='delete-user-confirmation' className='mb-lg text-black'>{`Are you sure you want to delete permissions for user ${name} from app ${appName}?`}</p>
        <Button id='delete-user-cancel-button' data-testid='delete-user-cancel-button' ocStyle='cancel' onClick={this.closeModal}>Cancel</Button>
        <Button id='delete-user-delete-button' data-testid='delete-user-delete-button' ocStyle='danger' className='ml-md' onClick={this.submit}>Yes, delete user</Button>
      </div>
    )
  }

  render() {
    const { error, isLoadingModal } = this.props
    return (
      <RequestStatus id='delete-user-permissions' data-testid='delete-user-permissions' error={error} isLoading={isLoadingModal}>
        {this.renderContent()}
      </RequestStatus>
    )
  }

}

export default connect(
  state => ({
    deletedUserId: state.appsManager.deletedUserId,
    error: state.appsManager.error,
    isLoadingModal: state.appsManager.isLoadingModal,
  }), {
    closeModalFn: closeModal,
    deleteUserFn: deleteUser,
  }
)(UserDeleteModal)
