import jwtDecode from 'jwt-decode'

const getUserFromEmail = email => email && email.split('@')[0]

const hasTimeExpired = exp => Date.now() >= exp * 1000

const tokenDecoder = token => {
  const decodedToken = jwtDecode(token)

  const usernameFromCognito = getUserFromEmail(decodedToken['cognito:username'])
  const usernameFromIdentity = decodedToken.identities && decodedToken.identities[0] && decodedToken.identities[0].userId
  const usernameFromOkta = usernameFromIdentity && usernameFromIdentity.indexOf('@') ? getUserFromEmail(usernameFromIdentity) : usernameFromIdentity
  const username = usernameFromOkta || usernameFromCognito

  return {
    exp: decodedToken.exp,
    username,
  }
}

export {
  hasTimeExpired,
  tokenDecoder,
}
