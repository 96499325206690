import { USER_ACCESS_PERMISSIONS } from '../constants/Permissions'

export default class AuthUtils {

  static hasPermission(user, appId, permission) {
    if (!user.permissions || !user.permissions[appId]) {
      return false
    }
    return user.permissions[appId][permission] === true
  }

  static hasAccessToRoute(user, route) {
    if (!route.permission) {
      return true
    }
    return this.hasPermission(user, route.id, route.permission)
  }

  static hasAccessToApp(user, appId) {
    if (!user || !user.permissions) {
      return false
    }
    const appNames = Object.keys(user.permissions)

    return appNames.includes(appId)
  }

  static hasManagePermissions(user, appId) {
    return this.hasPermission(user, appId, USER_ACCESS_PERMISSIONS.manageApp) || this.hasPermission(user, appId, USER_ACCESS_PERMISSIONS.manageUsers)
  }

}
