import { AUTH } from '../../constants/ActionTypes'

const initialState = {
  appConfig: null,
  error: null,
  isLoading: false,
  isLoggedIn: false,
  user: null,
}

export default function auth(state = initialState, action = null) {
  switch (action.type) {
  case AUTH.LOGIN_REQUEST:
  case AUTH.REFRESH_PERMISSIONS_REQUEST:
    return {
      ...state,
      error: null,
      isLoading: true,
    }

  case AUTH.LOGIN_SUCCESS:
  case AUTH.REFRESH_PERMISSIONS_SUCCESS:
    return {
      ...state,
      appConfig: action.payload.appConfig,
      error: null,
      isLoading: false,
      isLoggedIn: true,
      user: action.payload.user,
    }

  case AUTH.LOGIN_FAILURE:
    return {
      ...state,
      error: action.payload.message ? action.payload : { message: 'Unknown error, could not log you in at this time.' },
      isLoading: false,
    }

  // Fail gracefully, do nothing
  case AUTH.REFRESH_PERMISSIONS_FAILURE:
    return {
      ...state,
      isLoading: false,
    }

  case AUTH.LOGOUT:
    return initialState

  default:
    return state
  }
}
