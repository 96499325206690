/* eslint-disable import/max-dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { AppHeader, RequestStatus } from '@expediapartnersolutions/common-ui-lib'
import { Alert, Col, Row, Button, FontIcon } from '@expediapartnersolutions/ocean'

import { clearAppState, clearUpdatingApp, createApp, getUserPermissionsForApp, getAppSchema, getUsersAndSchemaForApp, listAppPermissions, loadSession, openModal, saveUserPermissionsForApp, updateApp } from '../../actions'
import { USER_ACCESS_PERMISSIONS } from '../../constants/Permissions'
import AuthUtils from '../../utils/AuthUtils'
import AnalyticsUserAccess from '../../utils/analyticsUserAccess'

import { AppSettings } from './components/AppSettings'
import { AppUsers } from './components/AppUsers'

export class AppsManager extends React.Component {

  static propTypes = {
    appUsers: PropTypes.array,
    apps: PropTypes.array,
    clearAppStateFn: PropTypes.func.isRequired,
    clearUpdatingAppFn: PropTypes.func.isRequired,
    createAppFn: PropTypes.func.isRequired,
    currentAppPermissionsSchema: PropTypes.object,
    deletedUserId: PropTypes.string,
    error: PropTypes.string,
    getAppSchemaFn: PropTypes.func.isRequired,
    getUserPermissionsForAppFn: PropTypes.func.isRequired,
    getUsersAndSchemaForAppFn: PropTypes.func.isRequired,
    hasAppBeenCreated: PropTypes.bool.isRequired,
    hasAppBeenUpdated: PropTypes.bool.isRequired,
    isDeprecatedAppSchema: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    listAppPermissionsfn: PropTypes.func.isRequired,
    loadSessionFn: PropTypes.func.isRequired,
    message: PropTypes.string,
    openModalFn: PropTypes.func.isRequired,
    permissionsForSelectedUser: PropTypes.object,
    saveUserPermissionsForAppFn: PropTypes.func.isRequired,
    updateAppFn: PropTypes.func.isRequired,
    updatingApp: PropTypes.object,
    user: PropTypes.object.isRequired,
  }

  state = {
    addUsers: false,
    currentApp: {},
    isCreatingApp: false,
  }

  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillMount() {
    this.props.listAppPermissionsfn()
  }

  /**
   * This prioritises apps with an existing summary over those without
   * Otherwise, orders them by the summary string
   */
  sortByAppName = (a, b) => {
    if (!!a.summary && !!b.summary) { // if both have summaries
      return a.summary.toUpperCase() < b.summary.toUpperCase() // compare case-insensitively by summary (puts in a->z order)
    }
    return !!a.summary - !!b.summary // else compare by having summary or not (puts without-summary before with-summary)
  }

  handleDisplayCreateApp = () => {
    this.props.clearAppStateFn()
    this.setState({ isCreatingApp: true })
    AnalyticsUserAccess.newAppPermissions()
  }

  handleDisplayAppManager = () => {
    this.props.clearUpdatingAppFn()
    this.props.loadSessionFn()
    this.setState({
      addUsers: false,
      isCreatingApp: false,
    })
  }

  handleDisplayUpdateApp = e => {
    this.props.clearAppStateFn()
    const app = JSON.parse(e.currentTarget.dataset.app)
    this.props.getAppSchemaFn(app)
    AnalyticsUserAccess.editAppPermission(app.appId)
  }

  handleDisplayManageUsers = e => {
    this.props.clearAppStateFn()
    const { app } = e.currentTarget.dataset

    this.setState({
      addUsers: true,
      currentApp: JSON.parse(app),
    })
    AnalyticsUserAccess.setUsersAndPermissions(app.appId)
  }

  renderActions(app) {
    const { user } = this.props
    return (
      <div className='text-right m-md'>
        <Button id={`${app.name}-settings`} data-testid={`${app.name}-settings`} ocStyle='link' className='text-black' onClick={this.handleDisplayUpdateApp} data-app={JSON.stringify(app)} disabled={!AuthUtils.hasPermission(user, app.name, USER_ACCESS_PERMISSIONS.manageApp)}><FontIcon icon='settings-cog'/></Button>
        <Button id={`${app.name}-users`} data-testid={`${app.name}-users`} ocStyle='link' className='text-black' data-app={JSON.stringify(app)} onClick={this.handleDisplayManageUsers} disabled={!AuthUtils.hasPermission(user, app.name, USER_ACCESS_PERMISSIONS.manageApp) && !AuthUtils.hasPermission(user, app.name, USER_ACCESS_PERMISSIONS.manageUsers)}><FontIcon icon='users'/></Button>
      </div>
    )
  }

  renderApp(app) {
    return (
      <React.Fragment key={app.name}>
        <hr/>
        <Row>
          <Col xs={12} sm={7} md={8} lg={8} className='pt-sm'>
            <h4 className='m-n'>{ app.summary }</h4>
            <p className=''><small>{ app.description }</small></p>
          </Col>
          <Col xs={12} sm={5} md={4} lg={4} className='pt-sm'>
            { AuthUtils.hasManagePermissions(this.props.user, app.name) && this.renderActions(app) }
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  renderNewAppButton() {
    return (
      <Button id='apps-manager-new-app-permissions' data-testid='apps-manager-new-app-permissions' className='mb-md btn-md' onClick={this.handleDisplayCreateApp}>
        New app permissions
      </Button>
    )
  }

  renderAppEditSuccessMessage() {
    const { hasAppBeenCreated, hasAppBeenUpdated } = this.props

    if (hasAppBeenCreated || hasAppBeenUpdated) {
      const message = hasAppBeenCreated ? 'Successfully set up new App permissions' : 'Successfully updated App permissions'
      return (
        <Alert id='app-edit-alert' data-testid='app-edit-alert' ocStyle='success'>
          <p className='pb-md' >{message}</p>
          <Button id='app-edit-alert-dismiss-button' ocSize='sm' ocStyle='cancel' onClick={this.props.clearAppStateFn} rounded>Dismiss</Button>
        </Alert>
      )
    }
  }

  renderContent() {
    const { apps } = this.props

    if (apps && apps.length === 0) {
      return <p id='apps-manager-no-results' data-testid='apps-manager-no-results'>No apps found.</p>
    }

    if (apps) {
      return (
        <div id='apps' data-testid='apps' className='mt-sm'>
          { apps.sort(this.sortByAppName).map(app => this.renderApp(app)) }
        </div>
      )
    }

    return null
  }

  renderPage() {
    const { currentAppPermissionsSchema, error, isLoading, updatingApp, user } = this.props
    const { addUsers, currentApp, isCreatingApp } = this.state

    const commonProps = {
      error,
      handleExitFn: this.handleDisplayAppManager,
      isLoading,
      user,
    }

    if (isCreatingApp) {
      return (
        <AppSettings
          createAppFn={this.props.createAppFn}
          onSuccessfulChangeFn={this.handleDisplayAppManager}
          onUnmountFn={this.props.listAppPermissionsfn}
          {...commonProps}
        />
      )
    }

    if (addUsers) {
      return (
        <AppUsers
          app={currentApp}
          appPermissionsSchema={currentAppPermissionsSchema}
          onUnmountFn={this.props.listAppPermissionsfn}
          getUserPermissionsForAppFn={this.props.getUserPermissionsForAppFn}
          getUsersAndSchemaForAppFn={this.props.getUsersAndSchemaForAppFn}
          appUsers={this.props.appUsers}
          permissionsForSelectedUser={this.props.permissionsForSelectedUser}
          saveUserPermissionsForAppFn={this.props.saveUserPermissionsForAppFn}
          message={this.props.message}
          openModalFn={this.props.openModalFn}
          deletedUserId={this.props.deletedUserId}
          {...commonProps}
        />
      )
    }

    if (updatingApp) {
      return (
        <AppSettings
          app={updatingApp}
          isDeprecatedAppSchema={this.props.isDeprecatedAppSchema}
          onSuccessfulChangeFn={this.handleDisplayAppManager}
          onUnmountFn={this.props.listAppPermissionsfn}
          updateAppFn={this.props.updateAppFn}
          {...commonProps}
        />
      )
    }

    return (
      <div>
        {this.renderNewAppButton()}
        {this.renderAppEditSuccessMessage()}
        <RequestStatus id='apps-manager' error={error} isLoading={isLoading}>
          {this.renderContent()}
        </RequestStatus>
      </div>
    )
  }

  render() {
    return (
      <main id='apps-manager' data-testid='apps-manager'>
        <AppHeader title='User Access' subtitle='Manage user access permissions for apps.'/>
        <div className='well'>
          {this.renderPage()}
        </div>
      </main>
    )
  }

}

export default connect(
  state => ({
    appUsers: state.appsManager.appUsers,
    apps: state.appsManager.apps,
    currentAppPermissionsSchema: state.appsManager.currentAppPermissionsSchema,
    deletedUserId: state.appsManager.deletedUserId,
    error: state.appsManager.error,
    hasAppBeenCreated: state.appsManager.hasAppBeenCreated,
    hasAppBeenUpdated: state.appsManager.hasAppBeenUpdated,
    isDeprecatedAppSchema: state.appsManager.isDeprecatedAppSchema,
    isLoading: state.appsManager.isLoading,
    message: state.appsManager.message,
    permissionsForSelectedUser: state.appsManager.permissionsForSelectedUser,
    updatingApp: state.appsManager.updatingApp,
    user: state.auth.user,
  }), {
    clearAppStateFn: clearAppState,
    clearUpdatingAppFn: clearUpdatingApp,
    createAppFn: createApp,
    getAppSchemaFn: getAppSchema,
    getUserPermissionsForAppFn: getUserPermissionsForApp,
    getUsersAndSchemaForAppFn: getUsersAndSchemaForApp,
    listAppPermissionsfn: listAppPermissions,
    loadSessionFn: loadSession,
    openModalFn: openModal,
    saveUserPermissionsForAppFn: saveUserPermissionsForApp,
    updateAppFn: updateApp,
  }
)(AppsManager)
