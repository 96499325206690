const EVENTS = {
  LOGIN: {
    ACTION: 'Login',
    LABEL: 'LoginUsername',
  },
}

const trackEvent = (eventAction, eventLabel, eventValue) => window.AppHubGlobal.analytics.trackEvent('Console', eventAction, eventLabel, eventValue)

const Analytics = {
  login: hashedUsername => trackEvent(EVENTS.LOGIN.ACTION, EVENTS.LOGIN.LABEL, hashedUsername),
}

export default Analytics
