/* eslint-disable import/max-dependencies, import/no-unassigned-import */
import 'babel-polyfill'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { AppHubGlobal, GoogleAnalyticsProvider } from '@expediapartnersolutions/app-hub-utils'

import './scss/base.scss'
import CoreApp from './js/apps/CoreApp'
import { login, logout } from './js/apps/CoreApp/actions'
import { MODAL } from './js/constants/ActionTypes'
import { MODAL_KEYS } from './js/constants/ModalConfig'
import reducers from './js/reducers'
import CookieUtils from './js/utils/CookieUtils'
import { SESSION } from './js/utils/SessionUtils'
import { hashDecoder } from './js/utils/URLTools'

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = __DEV__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose
const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
    )
  )
)

const localeContext = React.createContext({})

new AppHubGlobal(
  new GoogleAnalyticsProvider(window, __GOOGLE_ANALYTICS_REF__),
  () => {
    store.dispatch({
      payload: {
        modalProps: {
          ocSize: 'sm',
          title: 'Session Expired',
        },
        modalType: MODAL_KEYS.AUTH_MODAL,
      },
      type: MODAL.OPEN_MODAL,
    })
  },
  localeContext,
).init()

const { id_token: oktaToken } = window.location.hash ? hashDecoder(window.location.hash) : {}
if (oktaToken) {
  store.dispatch(login(oktaToken))
  window.history.replaceState(null, null, ' ')
} else {
  const token = CookieUtils.get(SESSION.token)
  if (token) {
    store.dispatch(login(token))
  } else {
    store.dispatch(logout())
  }
}

render((
  <Provider store={store}>
    <CoreApp/>
  </Provider>
), document.getElementById('app'))
