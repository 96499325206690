export default class ComparisonUtils {

  static compareUsersByName(u1, u2) {
    if (u1.details && u1.details.name) {
      if (u2.details && u2.details.name) {
        return u1.details.name.localeCompare(u2.details.name)
      }
      return -1
    } else if (!u2.details || !u2.details.name) {
      return 0
    }
    return 1
  }

}
