import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormControl, FormGroup, Tag } from '@expediapartnersolutions/ocean'

import Keys from '../constants/Keys'

export default class TagsInput extends React.Component {

  static propTypes = {
    id: PropTypes.string,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    ocSize: PropTypes.oneOf(['xs', 'sm', 'lg']),
    ocStyle: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'cancel', 'upload', 'success', 'info', 'warning', 'danger', 'link']),
    onChange: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    isDisabled: false,
    label: '',
    ocSize: 'xs',
    ocStyle: 'primary',
    tags: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      tags: props.tags,
      value: '',
    }
  }

  handleTagRemove = attrs => {
    const { name, onChange } = this.props
    const { tags } = this.state
    const newTags = tags.filter(element => element !== attrs.text)
    this.setState({
      tags: newTags,
    }, () => {
      onChange(name, newTags)
    })
  }

  handleNewTag = event => {
    const { value } = this.state
    if (event.key === Keys.enter && value) {
      const { name, onChange } = this.props

      this.setState(prevState => {
        const newTags = new Set(prevState.tags).add(value)
        return {
          tags: Array.from(newTags),
          value: '',
        }
      }, () => {
        onChange(name, this.state.tags)
      })
    }
  }

  createTags() {
    const { isDisabled, name, ocSize, ocStyle } = this.props
    const { tags } = this.state

    return Array.from(tags).map((text, index) => (
      <Tag
        key={`${name}-${index}-tag`}
        ocStyle={ocStyle}
        ocSize={ocSize}
        text={text}
        className={`mb-sm ${index > 0 ? 'ml-sm' : null}`}
        onRemove={isDisabled ? null : this.handleTagRemove}
      >
        {text}
      </Tag>
    ))
  }

  handleChangeInput = (name, value) => this.setState({ value })

  render() {
    const { className, id, isDisabled, label, name, ocSize, ocStyle, onChange, tags, ...other } = this.props
    const tagsInputClass = classNames('tags-input', className, {
      'tags-input--base': true,
      'tags-input--disabled': isDisabled,
    })

    return (
      <FormGroup id={id} data-testid={id} className={tagsInputClass} onKeyPress={this.handleNewTag} {...other}>
        <FormControl
          className='mb-sm tags-input--input'
          name={`${name}-input`}
          onChange={this.handleChangeInput}
          disabled={isDisabled}
          label={label}
          value={this.state.value}
        />
        {this.createTags()}
      </FormGroup>
    )
  }

}
