import { AUTH, MODAL } from '../../constants/ActionTypes'
import { MODAL_KEYS } from '../../constants/ModalConfig'
import APIUtils from '../../utils/APIUtils'
import CookieUtils from '../../utils/CookieUtils'
import SessionUtils, { SESSION } from '../../utils/SessionUtils'

import { hasTimeExpired, tokenDecoder } from './utils'

const logout = () => dispatch => {
  SessionUtils.clear()
  dispatch({ type: AUTH.LOGOUT })
  window.location.assign(__SSO_HOST__)
}

const getAppConfig = async (dispatch, username) => {
  const [appsMetadataResponse, permissionsResponse] = await Promise.all([
    APIUtils.get(dispatch, `${__APPS_METADATA_HOST__}/application`),
    APIUtils.get(dispatch, `${__PERMISSIONS_HOST__}/tools/user/${username}?excludeGroupDetails=true`),
  ])

  const appConfig = appsMetadataResponse.data ? Object.values(appsMetadataResponse.data) : []
  const permissions = permissionsResponse.data || {}

  const user = {
    permissions,
    username,
  }
  window.localStorage.setItem(SESSION.user, JSON.stringify(user))

  return [user, appConfig]
}

const login = token => async dispatch => {
  const { exp, username } = tokenDecoder(token)

  if (hasTimeExpired(exp)) {
    return dispatch(logout())
  }

  dispatch({ type: AUTH.LOGIN_REQUEST })
  CookieUtils.set(SESSION.token, token, new Date(exp * 1000), __COOKIE_DOMAIN__)
  try {
    const [user, appConfig] = await getAppConfig(dispatch, username)

    dispatch({ type: AUTH.LOGIN_SUCCESS, payload: {
      appConfig,
      user,
    } })
  } catch (error) {
    SessionUtils.clear()
    if (error.response && error.response.status === 401) {
      dispatch({ type: AUTH.LOGIN_FAILURE, payload: { message: error.response.data } })
    }
  }
}

const loadSession = () => async dispatch => {
  const { username } = SessionUtils.getUser() || {}

  if (username) {
    dispatch({ type: AUTH.REFRESH_PERMISSIONS_REQUEST })
    try {
      const [user, appConfig] = await getAppConfig(dispatch, username)

      dispatch({ type: AUTH.REFRESH_PERMISSIONS_SUCCESS, payload: {
        appConfig,
        user,
      } })
    } catch (e) {
      SessionUtils.clear()
      dispatch({ type: AUTH.REFRESH_PERMISSIONS_FAILURE })
      dispatch({
        type: MODAL.OPEN_MODAL, payload: {
          modalType: MODAL_KEYS.AUTH_MODAL,
          modalProps: {
            ocSize: 'sm',
            title: 'Session Expired',
          },
        },
      })
    }
  }
}

export {
  loadSession,
  login,
  logout,
}
